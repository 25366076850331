@font-face {
  font-family: "Tajawal";
  src: url("./fonts/Tajawal-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Tajawal";
  src: url("./fonts/Tajawal-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

.dashboard {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
